import { SET_WOHNEIGENTUM_DATA } from "../constants/actions";
import { FinanceBoxProps } from "../types";
import { api } from "../utils/api";

export type WohneigentumData = {
  title: string;
  text: string;
  homeOnwershipBox: {
    maxAmount: number;
    subTitle: string;
    maxAge: number
    projectedGraphUnavailable: boolean;
    noDataInfo: string;
    projectedGraph: {
      date: string;
      year: number;
      value: number;
    }[];
    isVisible: boolean;
    link: string;
    linkText: string;
  };
  actionsBox: {
    title: string;
    text: string;
    isVisible: boolean;
    actionList: {
      title: string;
      text: string;
      link: string;
      linkText: string;
    }[];
  };
  simulationBox: {
    title: string;
    toolTip: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  financeExpertBox: FinanceBoxProps;
  fundingRequestBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  mortageRequestBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  contactBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  homeOnwershipPageContentBox: {
    content: string;
  };
};

export const getWohneigentumData = (payload) => {
  return async function (dispatch) {
    try {
      const response: { data: { data: WohneigentumData } } = await api.get(
        "pages/homeownership"
      );
      dispatch({
        type: SET_WOHNEIGENTUM_DATA,
        payload: response.data.data,
      });
      return response;
    } catch (err) {
      return err;
    }
  };
};
